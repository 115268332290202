import { Box, Text, ThemeContext, Grid, Image } from 'grommet';
import React from 'react';
import { useHistory } from 'react-router-dom';

import Modal from './Modal';
import { useUsers } from '../../context/users';

interface ModalProps {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  unitID: number;
}

// TODO: should accept invoice object from the card
const ItemModal = ({ setShow, unitID }: ModalProps): JSX.Element => {
  const { boxes, appointments } = useUsers();
  const filterData = boxes.filter(row => row.unitID === unitID);
  console.log(filterData, boxes, appointments)

  return (
    <ThemeContext.Extend
      value={{
        button: {
          border: {
            radius: '5px',
          },
        },
      }}
    >
      <Modal
        setShow={setShow}
        width={{ min: 'medium' }}
        headerBackground="none"
        bodyPad={{ bottom: 'medium', top: 'none' }}
        header={
          <>
            <Box fill direction="row" justify="between">
              <Text weight="bold">Items</Text>
            </Box>
          </>
        }
      >
      <Box overflow={{ vertical: 'auto' }} height={{ max: 'large' }}>
        <Grid
          rows="small"
          gap="medium"
        >

        {filterData.map(item => (
          <>
          <Box direction = 'row'
                border={{ color: 'black', size: 'small'}}
                round='small'>  
          <Box width = 'small'
                pad = 'small'>  
            <Text weight="bold">
              Description:{' '}
              <Text weight="normal" color="dark-3">
                {item.description}
              </Text>
            </Text>
            <Text weight="bold">
              ID:{' '}
              <Text weight="normal" color="dark-3">
                {item.id}
              </Text>
            </Text>
            <Text weight="bold">
              Status: {' '}
              <Text weight="normal" color="dark-3">
                {item.status}
              </Text>
            </Text>
            {/*<Text weight="bold">
              Location:{' '}
              <Text weight="normal" color="dark-3">
                {item.location}
              </Text>
        </Text>*/}
            </Box>
              <Image src= {`https://dualappbucket211930-dev.s3.us-east-2.amazonaws.com/public/${item.photo}`}
              />
            </Box> 
          </>
          ))
        }
      </Grid> 
      </Box>
      </Modal>
    </ThemeContext.Extend>
  );
};

export default ItemModal;
