import { Nav } from 'grommet';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

// Removed Invoices Tab

const pages: string[] = [
  'dashboard',
  'orders',
  'customers',
  'inventory',
  'drivers',
];

const Navbar = (): JSX.Element => {
  const activeStyle = {
    backgroundColor: '#7B1FA2', // TODO: global color vars? or different place to put css?
    fontWeight: 400,
  }

  const StyledNavLink = styled(NavLink)`
    text-decoration: none;
    color: white;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid transparent;
    &:hover {
      border: 1px solid #7b1fa2;
    }
  `;

  return (
    <Nav
      justify="start"
      direction="row"
      width="100%"
      pad={{ vertical: 'small', horizontal: '20px' }}
      background="brand"
      gap="medium"
    >
      {pages.map(page => (
        <StyledNavLink key={page} to={`/Admin/${page}`} activeStyle={activeStyle}>
          {page.charAt(0).toUpperCase() + page.slice(1)}
        </StyledNavLink>
      ))}
    </Nav>
  );
};

export default Navbar;
