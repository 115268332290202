import { API } from 'aws-amplify';
import { Box, Text, Select, Grid, Image } from 'grommet';
import React from 'react';

import Modal from './Modal';
import { useUsers } from '../../context/users';
import { OrderType } from '../../context/model/types';
import { updateOrder } from '../../graphql/mutations';
import StyledButton from '../StyledButton';

// TODO: probably want data validation so it's not too big
// prolly on server side

interface ModalProps {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  orderID: number;
}

const OrderModal = ({ setShow, orderID }: ModalProps): JSX.Element => {

  const confirmSubmit = async (driverInput: string, orderData: OrderType) => {
    if (driverInput === "None") {
      alert('Please select a driver')
    } else {
      if(orderData.status == "PENDING") {
        sendEmail(orderData, driverInput);
      }
      const orderDetail = {id: orderData.id, driver: driverInput, status: "INCOMPLETE"}
      await API.graphql({query: updateOrder, variables: {input: orderDetail}});
      setShow(false);
      window.location.reload();
    }
  };

  const logValue = async (driverInput: string, orderData: OrderType) => {
    sendEmail(orderData, driverInput);
    console.log("1: ", orderData)
    console.log("2: ", JSON.stringify({ orderData }))
  };

  const sendEmail = (orderData, driver) => {
    fetch("https://215uigjfub.execute-api.us-east-2.amazonaws.com/alpha/sendEmailToTenant", {
      method: 'POST',
      headers: {
          'X-API-Key': 'skj1FBKfqJ6iEkTR7g6ON1IB7mZYC8N31pcObv7S',
          'Content-Type': 'application/json' // Specify content type
      },
      body: JSON.stringify({ orderData, driver }),// Pass recipient email in the request body
    })
    .then(res => {
      if (!res.ok) {
        throw new Error('Failed to fetch: ' + res.status + ' ' + res.statusText);
      }
      return res.json();
    })
    .then(json => {
      // Handle successful response
      console.log(json);
    }) 
    .catch(function (err) {
      console.error("Error:", err);
    });
  }
  
  const { units } = useUsers();

  const { orders } = useUsers();
  const filterData = orders.filter(row => row.id === orderID);

  const { drivers } = useUsers();
  const [value, setValue] = React.useState(filterData[0].driver);
  console.log(filterData[0])

  const getUserUnit = (tenantID) => {
    const user = units.find(unit => unit.tenantID === tenantID);
    return user ? user.sitelinkUnit.sUnitName : null;
  }

  return (
    <Modal
      setShow={setShow}
      header="Manage Order"
    >
    {filterData.map(item => (
      <Box gap="large" pad={{ horizontal: 'large' }}>
        <Box direction="column" gap="medium">
          <Box height="medium" gap="small">
          <Text weight="bold">
              Job Type:{' '}
              <Text weight="normal" color="dark-3">
                {item.jobType}
              </Text>
            </Text>
            <Text weight="bold">
              Name:{' '}
              <Text weight="normal" color="dark-3">
                {item.tenantID}
              </Text>
            </Text>
            <Text weight="bold">
              Address:{' '}
              <Text weight="normal" color="dark-3">
                {item.address.addressLine1 + ", " + item.address.city + ", " + item.address.state} 
              </Text>
            </Text>
            <Text weight="bold">
              Date:{' '}
              <Text weight="normal" color="dark-3">
                {item.date}
              </Text>
            </Text>
            <Text weight="bold">
              Time:{' '}
              <Text weight="normal" color="dark-3">
                {item.time}
              </Text>
            </Text>

            {(item.status === 'PENDING' || item.status === 'INCOMPLETE') && item.jobType === 'PICKUP' ? (
              <Text weight="bold">
                Items:{' '}
                <Text weight="normal" color="dark-3">
                  {item.orderItems.join(', ')}
                </Text>
              </Text>
            ) : (              
              <Text weight="bold">
              Items:{' '}              
              <Box overflow={{ horizontal: 'auto' }}>
                <Grid
                  rows="xsmall"
                  gap="xsmall"
                  columns={{size: "xsmall", count: 100}}
                >
                {item.orderItems.map(obj => (
                  <>
                    <Box direction = 'row'>
                      <Box width="xsmall">
                        <Box border={{ color: 'black', size: 'small'}} round='small'>
                        <Image fit="cover" src= {`https://dualappbucket211930-dev.s3.us-east-2.amazonaws.com/public/${item.tenantID}_${obj}`}/>
                        </Box> 
                        <Text weight="normal" color="dark-3">
                          {obj}
                        </Text>
                      </Box>
                    </Box>
                  </>
                  ))
                }
                </Grid> 
              </Box>
            </Text>
            )}
            <Text weight="bold">
              Packing Help Required:{' '}
              <Text weight="normal" color="dark-3">
                {item.needPack}
              </Text>
            </Text>
            <Text weight="bold">
              Unit:{' '}
              <Text weight="normal" color="dark-3">
                {getUserUnit(item.tenantID)}
              </Text>
            </Text>
            {item.status === 'COMPLETED' ? (
              <Box>
                <Text weight="bold">
                  Driver:{' '}
                  <Text weight="normal" color="dark-3">
                    {item.driver}
                  </Text>
                </Text>              
                <Text weight="bold">
                  Moving Time:{' '}
                  <Text weight="normal" color="dark-3">
                    {item.movingTime} min
                  </Text>
                </Text>  
              </Box>
                ) : (
              <Text weight="bold">
                Driver:{' '}
                <Select
                  options={drivers}
                  labelKey={"name"}
                  valueKey={{key: 'id', reduce: true}}
                  value={value}
                  size='small'
                  onChange={({ value: option }) => {setValue(option)}}
                  plain={true}
                />
              </Text>
                )}                
          </Box>
        </Box>
        {item.status === 'COMPLETED' ? (
        <Box direction="row" justify="center">
      </Box>
              ) : (
        <Box direction="row" justify="center">
          <StyledButton label="Save" onClick={() => confirmSubmit(value, item)} />
        </Box>
                )}
      </Box>
      ))
     }
    </Modal>
  );
};

export default OrderModal;
