/* eslint-disable */
import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import { Grommet, Box } from 'grommet';

// imported components
import Landing from './Landing.js'

// imported styles
import './styles/NavBar.css'


export const NavBar = () => {
    return (
        <div>
          <header>
            <Link to='/'><h4>Bins Storage</h4></Link>
            <div class="topnav">
            <Link to='/About'><Box pad={'small'}>About</Box></Link>
            <Link to='/Contact'><Box pad={'small'}>Contact Us</Box></Link>
            <Link to='/Admin'><Box background={'#261136'} round={'medium'} pad={"small"}>Admin Portal</Box></Link>
            </div>
            <div class="shrink">
              <Link to='/Admin'><Box background={'#261136'} round={'medium'} pad={"small"}>Admin Portal</Box></Link>
            </div>
          </header>
        </div>
    );
};
