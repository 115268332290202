import { API } from 'aws-amplify';
import { Box, Text, Select, TextInput, Spinner } from 'grommet';
import React from 'react';

import Modal from './Modal';
import { CreateUnitInput, CreateUnitMutation, SitelinkUnitInput } from '../../API';
import { Facility, OrderType, Unit, User } from '../../context/model/types';
import { useUsers } from '../../context/users';
import { createUnit, updateBox, updateOrder, updateTenant, updateUnit, updateFacility } from '../../graphql/mutations';
import { SitelinkUnit } from '../../utils/sitelink/types';
import StyledButton from '../StyledButton';
import { ExecuteSitelinkMoveIn } from '../../utils/sitelink/sitelink';
import { SitelinkMoveInFailure } from '../../utils/sitelink/types';

// TODO: probably want data validation so it's not too big
// prolly on server side

interface ModalProps {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  orderID: number;
}

const FinishOrderModal = ({ setShow, orderID }: ModalProps): JSX.Element => {

  /* eslint-disable */
  /*
   * First, wraps the sitelinkUnit in a Unit type, and then fires the api call to update the DynamoDB.
   */
  const confirmSubmit = async (order: OrderType, sitelinkUnit: SitelinkUnit, facility: Facility, tenant: User | undefined, tenantUnits: Unit[]) => {
    if (!tenant) {
      console.error('tenant is undefined, but it should never be so at this point.')
      return;
    }
    
    const orderID = order.id
    const tenantEmail = order.tenantID

    try {
      setIsLoading(true)
      // TODO(bobby): Remove dummy values for tsting
      const sitelinkMoveInResult = await ExecuteSitelinkMoveIn(
        'TEST', 'TEST', 'TEST', 'TEST', tenant.sitelinkTenantID, tenant.accessCode, sitelinkUnit.UnitID, order.date, 1, 1234123412341234, 123, '11-11-2031',
        '', '', 1, 1);
      if (!sitelinkMoveInResult) {
        console.error('Sitelink MoveIn failed')
        return;
      }
      // Albert removed ['RT'] before each ['Ret_Code'] in the two lines below (3x)
      if (sitelinkMoveInResult['Ret_Code'] < 0 && sitelinkMoveInResult['Ret_Msg']) {
        const sitelinkErrMsg = sitelinkMoveInResult['Ret_Msg'];
        console.error(`Error finishing MoveIn to sitelink: ${sitelinkErrMsg}`);
        alert(sitelinkErrMsg);
        return;
      }
      
      // create a new unit in dynamodb if necessary.
      const unitID = await grabExistingUnitOrCreateNew(facility, sitelinkUnit, tenantEmail, tenantUnits)

      for (let i = 0; i < order.orderItems.length; i+=1) {
        const boxDetail = {id: order.orderItems[i], status: "IN_STORAGE", unitID: unitID}
        await API.graphql({query: updateBox, variables: {input: boxDetail}});
      }
      const orderDetail = {id: orderID, status: "COMPLETED", movingFee: movingFee}
      await API.graphql({query: updateOrder, variables: {input: orderDetail}});
      const unitDetail = {id: unitID, tenantID: tenantEmail, usage: usageValue}
      await API.graphql({query: updateUnit, variables: {input: unitDetail}});
      const tenantDetail = {id: tenantEmail, unitID: unitID}
      await API.graphql({query: updateTenant, variables: {input: tenantDetail}});
      setShow(false);
      window.location.reload();
    } catch (e: unknown) {
      alert('An internal error occurred while updating the unit - please try again!')
      console.error(e);
    }
  };

  // If the given sitelink unit id belongs to an existing unit, returns that unit. Else, returns a newly created unit.
  const grabExistingUnitOrCreateNew = async (facility: Facility, sitelinkUnit: SitelinkUnit, tenantID: string, tenantUnits: Unit[]): Promise<string> => {
    const maybeTenantUnit = tenantUnits.find(unit => unit.sitelinkUnit.UnitID === sitelinkUnit.UnitID)
    if (maybeTenantUnit) {
      return maybeTenantUnit.id.toString()  // Definitely tenant's unit
    }

    // Make a new unit if sitelink unit ID does not belong to an existing unit.
    const newUnit = {
      id: sitelinkUnit.UnitID.toString(),
      facilityID: facility.id,
      sitelinkUnit: sitelinkUnit,
      size: Math.trunc(sitelinkUnit.dcLength) + 'x' + Math.trunc(sitelinkUnit.dcWidth),
      usage: '0%',
      price: '$' + Math.trunc(sitelinkUnit.dcStdRate).toString(), 
      tenantID: tenantID,
    } as CreateUnitInput

    try {
      console.log('trying to type assert')
      const a = newUnit.sitelinkUnit as SitelinkUnitInput
      console.log('success')
    } catch (e: unknown) {
      console.log('err: ', e)
    }
    try {
      // Create the unit
      console.log(newUnit)
      const unit = (await API.graphql({query: createUnit, variables: {input: newUnit}})) as {data: CreateUnitMutation}
      // TODO(dynamodb): add this to the facility in dynamodb too
      if (!unit.data.createUnit) {
        throw new Error('DynamoDB CreateUnitMutation Failed')
      }
      return unit.data.createUnit.id
    } catch (e: unknown) {  // TODO: rethrowing an error is a codesmell. Handle it and return appropriately.
      console.error('Error creating unit: ', e)
      const err = e as {data: any, errors: Array<any>}
      console.log('Error creating unitt: ', err.errors[0]);
      throw e;
    }
  }

  /* eslint-enable */
  const [isLoading, setIsLoading] = React.useState(false);
  const { orders, units, sitelinkUnits, facilityInfo, users } = useUsers();
  // TODO(sitelink): narrow the type of the facility global state var. Ideally, it should not be an array (it should be narrowed to this facility)
  // TODO(sitelink): narrow the type of the facility to not use any. I had to console.log the facility info to figure out the data available. Read the fetchFacility code in users.tsx to see the problem and not how it uses an any parameter
  const orderMetadata = orders.filter(order => order.id === orderID);  
  // tenant may already have unit
  const tenant = users.find(user => orderMetadata[0].tenantID === user.id)
  console.log('is tenant null: ', tenant);
  const tenantUnits = units.filter(unit => unit.tenantID === orderMetadata[0].tenantID);
  const tenantSitelinkUnits = tenantUnits.map(unit => unit.sitelinkUnit)
  const unrentedSitelinkUnits = sitelinkUnits.map(unit => unit.Table)
  const availableSitelinkUnits = orderMetadata[0].isInitialOrder ? unrentedSitelinkUnits : tenantSitelinkUnits
  const [selectedUnit, setSelectedUnit] = React.useState(availableSitelinkUnits[0]);
  const [usageValue, setUsage] = React.useState(orderMetadata[0].isInitialOrder ? "0%" : tenantUnits[0].usage)

  console.log('tenant units: ', tenantUnits);
  console.log('tenantSitelinkUnits: ', tenantSitelinkUnits);
  console.log('unrentedSitelinkUnits: ', unrentedSitelinkUnits);
  console.log('availableSitelinkUnits: ', availableSitelinkUnits);

  console.log("FacilityIno:", facilityInfo)
  console.log("orderInfo:", orders)
  const truckFee = facilityInfo?.movingCosts.flatFee
  const hourlyFee = facilityInfo?.movingCosts.hourlyFee
  const movingTime = orderMetadata[0].movingTime
  const multiplier = orderMetadata[0].isInitialOrder ? 0 : 1
  const effectiveTruckFee = truckFee !== undefined ? truckFee : 0;
  const effectiveHourlyFee = hourlyFee !== undefined ? hourlyFee : 0
  const [movingFee, setMovingFee] = React.useState((effectiveTruckFee+effectiveHourlyFee*Math.round((movingTime/60)))*multiplier);

  if (!facilityInfo) {
    return (
      <Modal setShow={setShow}>
          <Text>Unable to find an available unit in Sitelink!</Text>
      </Modal>
    )
  }
  const facility = facilityInfo;

  if (!selectedUnit) {
    return (
      <Modal setShow={setShow}>
        <Text>Unable to find an available unit in Sitelink!</Text>
      </Modal>
    );
  }

  const test = (val) => {
    console.log(val)
  }

  return (
    <Modal
      setShow={setShow}
      header="Finish Order"
    >
    { isLoading ? (
    <>
      <Spinner/>
    </> ) : ( 
    <>
    {orderMetadata.map(order => (
      <Box gap="medium" pad={{ horizontal: 'large' }}>
        <Box direction="column" gap="medium">
          <Box height="medium" gap="small">
            <Text weight="bold">
              Name:{' '}
              <Text weight="normal" color="dark-3">
                {order.tenantID}
              </Text>
            </Text>
            <Text weight="bold">
              Address:{' '}
              <Text weight="normal" color="dark-3">
                {order.address.addressLine1 + ", " + order.address.city + ", " + order.address.state}
              </Text>
            </Text>
            <Text weight="bold">
              Date:{' '}
              <Text weight="normal" color="dark-3">
                {order.date}
              </Text>
            </Text>
            <Text weight="bold">
              Time:{' '}
              <Text weight="normal" color="dark-3">
                {order.time}
              </Text>
            </Text>
            <Text weight="bold">
              Driver:{' '}
              <Text weight="normal" color="dark-3">
                {order.driver}
              </Text>
            </Text>
            <Text weight="bold">
              Items:{' '}
              <Text weight="normal" color="dark-3">
                {order.orderItems.toString()}
              </Text>
            </Text>
            {order.isInitialOrder ? (
              <Box gap="small">
                <Text weight="bold">
                  Requested Size:{' '}
                  <Text weight="normal" color="dark-3">
                    {tenant.plan.size}
                  </Text>
                </Text>
                <Text weight="bold">
                  Moving Fees:{' '}
                  <Text weight="normal" color="dark-3">
                    None
                  </Text>
                </Text>
              </Box> 
            ) : (
              <Box direction='row' align="center">
                <Text weight="bold">
                Moving Fees:{' '}
                </Text>
                <Text weight="normal" color="dark-3">
                  ${hourlyFee} hourly fee x {Math.round((order.movingTime)/60)} hours + ${truckFee} truck fee = $
                </Text>
                <Box>
                <TextInput
                  type="number" 
                  width="xsmall"
                  value={movingFee}
                  onChange={event => setMovingFee(event.target.valueAsNumber)}
                />
                </Box>
              </Box>
            )}
            <Box direction='row' align="center">
            <Text weight="bold">
              Usage:{' '}
            </Text>
                <Box>
                <TextInput
                  value={usageValue}
                  onChange={event => setUsage(event.target.value)}
                />
                </Box>
            </Box>
            <Text weight="bold">
              Unit: {' '}
              <Select
                options={availableSitelinkUnits}
                labelKey={option => {
                  const dcLength = !isNaN(parseFloat(option.dcLength)) ? parseFloat(option.dcLength).toFixed(0) : '';
                  const dcWidth = !isNaN(parseFloat(option.dcWidth)) ? parseFloat(option.dcWidth).toFixed(0) : '';
                  return `${option.sUnitName} - ${dcLength}x${dcWidth}`; // Adding dcWidth after dcLength with "x" in between
                }}
                valueKey='UnitID'
                value={selectedUnit}
                size='small'
                onChange={({ option }) => {setSelectedUnit(option)}}
                plain={true}
              />
            </Text>
          </Box>
        </Box>
        <Box direction="row" justify="center">
          <StyledButton label="Approve" onClick={() => confirmSubmit(order, selectedUnit, facility, tenant, tenantUnits)} />
        </Box>
      </Box>
      ))
     }
     </>
    )}
    </Modal>
  );
};

export default FinishOrderModal;
